<!-- Created by henian.xu on 2020/5/26. -->

<template>
    <Page>
        <Container>
            <template v-if="detail">
                <ScheduleItem
                    :data="detail"
                    no-list
                />
                <CellGroup
                    class="ma-t"
                    border
                >
                    <Cell
                        label="理论单项考试"
                        direction="column"
                    >
                        <template #extra>
                            <div class="extra">
                                <div>时间: {{theoryExam.timeBegin | moment('YYYY-MM-DD HH:mm:ss')}} - {{theoryExam.timeEnd | moment('YYYY-MM-DD HH:mm:ss')}}</div>
                                <div
                                    v-if="theoryExam.score"
                                    class="ma-t ta-r tc-base"
                                >成绩: {{theoryExam.score}}<template v-if="theoryExam.passStr">({{theoryExam.passStr}})</template></div>
                            </div>
                        </template>
                    </Cell>
                    <Cell
                        v-if="practiceExam"
                        label="实操单项考试"
                        direction="column"
                    >
                        <template #extra>
                            <div class="extra">
                                <div>时间: {{practiceExam.timeBegin | moment('YYYY-MM-DD HH:mm:ss')}} - {{detail.practiceExamTimeEnd | moment('YYYY-MM-DD HH:mm:ss')}}</div>
                                <div
                                    v-if="practiceExam.score"
                                    class="ma-t ta-r tc-base"
                                >成绩: {{practiceExam.score}}</div>
                            </div>
                        </template>
                    </Cell>
                </CellGroup>
                <CellGroup
                    class="ma-t"
                    border
                >
                    <Cell
                        label="考试说明"
                        direction="column"
                    >
                        <template #extra>
                            <div
                                class="extra"
                                v-html="examDesc || '无'"
                            ></div>
                        </template>
                    </Cell>
                </CellGroup>

                <div class="paper-list">
                    <div
                        class="item"
                        v-for="(item,index) in examApplyPaperList"
                        :key="index"
                    >
                        <div class="label">
                            <div class="count">第{{index + 1}}次答卷</div>
                            <div
                                v-if="item.examResult"
                                class="score"
                            >{{item.examScore}}分</div>
                        </div>
                        <div class="content">
                            时间: {{ item._timeStr||'未开始' }}
                        </div>
                    </div>
                </div>
            </template>
            <RecognitionDialog
                ref="recognitionDialog"
                show-close-btn
                :params="{recognitionType:1,certExamExamId:(certExamExamDetail||{}).id,certExamApplyId}"
            ></RecognitionDialog>
            <Dialog ref="proveDialog">
                <div class="ta-c">
                    <XImage :src="proveSrc"></XImage>
                    <div class="tc-minor pa-as">长按图片保存</div>
                </div>
            </Dialog>
        </Container>
        <PageBar
            v-if="surplusExamNum"
            position="bottom"
        >
            <XButton
                class="item"
                label="开始考试"
                theme="success"
                :disabled="submitting"
                @click="()=>{onSubmit()}"
            ></XButton>
        </PageBar>
        <PageBar
            v-else-if="isShowProve"
            position="bottom"
        >
            <XButton
                class="item"
                label="查看成绩证明"
                theme="primary"
                @click="()=>{onShowProve()}"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
import { pageMixin } from 'mixins';
import ScheduleItem from './_ScheduleItem';

export default {
    name: 'Detail',
    mixins: [pageMixin],
    components: { ScheduleItem },
    data() {
        return {
            verticalGroupingId: this.$route.params.id,
            detail: null,
            submitting: false,
        };
    },
    beforeRouteEnter(to, from, next) {
        const { name } = from;
        next(vm => {
            if (name === 'examPaper') {
                vm.getDetail();
            }
        });
    },
    computed: {
        certExamExamDetail() {
            return (this.detail || {}).certExamExamDetail;
        },
        certExamApplyId() {
            return (this.detail || {}).certExamApplyId;
        },
        examNum() {
            const { certExamExamDetail = {} } = this;
            return certExamExamDetail.examNum || 0;
        },
        certExamApplyPaperId() {
            const { certExamExamDetail = {} } = this;
            return certExamExamDetail.certExamApplyPaperId;
        },
        examApplyPaperList() {
            const { certExamExamDetail = {}, examNum } = this;
            if (!examNum) return [];
            const list = (certExamExamDetail.examApplyPaperList || []).map(item => {
                const { beginTime, finishTime } = item;
                const format = 'YYYY-MM-DD HH:mm:ss';
                return {
                    ...item,
                    _timeStr: `${this.$moment(beginTime).format(format)}~${this.$moment(finishTime).format(format)}`,
                };
            });
            const surplusList = Array(examNum - list.length).fill({});
            return list.concat(surplusList);
        },
        surplusExamNum() {
            return this.examApplyPaperList.reduce((pre, cur) => {
                return pre + (cur.id ? 0 : 1);
            }, 0);
        },
        theoryExam() {
            const { detail, certExamExamDetail = {} } = this;
            return {
                timeBegin: certExamExamDetail.examBeginTime || detail.theoryExamTimeBegin,
                timeEnd: certExamExamDetail.examEndTime || detail.theoryExamTimeEnd,
                score: certExamExamDetail.examScore || detail.theoryExamResult,
                passStr: certExamExamDetail.examResultName || detail.examResultName || '',
            };
        },
        practiceExam() {
            const { detail, certExamExamDetail } = this;
            if (certExamExamDetail) return null;
            return {
                timeBegin: detail.practiceExamTimeBegin,
                score: detail.practiceExamResult,
            };
        },
        examDesc() {
            const { detail, certExamExamDetail = {} } = this;
            return certExamExamDetail.examDesc || detail.verticalGroupingDesc || '';
        },
        isShowProve() {
            return (this.detail || {}).isShowProve;
        },
        proveSrc() {
            return `${
                this.$globalVar.isDev ? this.$globalVar.appConfig.AXIOS_DEFAULT_CONFIG.baseURL : '/rpc'
            }/comm/rs/jasper/previewCertExamApplyProve?verticalGroupingId=${this.verticalGroupingId}`;
        },
    },
    methods: {
        async getDetail() {
            const { data } = await this.$api.Member.Ce.VerticalGrouping.verticalGroupingDetail(this.verticalGroupingId);
            this.detail = data;
        },
        async createExam() {
            const { certExamApplyId, certExamExamDetail, verticalGroupingId } = this;
            const res = await this.$api.Member.Ce.CertExamPaper.exam(
                {
                    certExamApplyId,
                    certExamExamId: certExamExamDetail.id,
                    verticalGroupingId,
                    paperType: 1,
                },
                {
                    isHandleError: true,
                },
            );
            const { data, success, code, msg } = res;
            if (!success) {
                if (code === 1002) {
                    await this.$refs.recognitionDialog.validate();
                    await this.$nextTick();
                    return this.createExam();
                }
                this.$messageBox.alert(msg);
                return Promise.reject(res);
            }
            return data;
        },
        async onSubmit() {
            const { submitting } = this;
            if (submitting) return;
            this.submitting = true;
            let { certExamApplyPaperId } = this;
            if (certExamApplyPaperId) {
                try {
                    await this.$messageBox.confirm('您有未完成的考试?', '温馨提示', {
                        cancelBtnText: '重新考试',
                        confirmBtnText: '继续考试',
                        isClickOtherClose: false,
                    });
                    await this.$router.push(`/exam/paper/${certExamApplyPaperId}`);
                    setTimeout(() => {
                        this.submitting = false;
                    }, 500);
                    return;
                } catch (e) {
                    // noop
                }
            }
            try {
                certExamApplyPaperId = await this.createExam();
                await this.getDetail();
                this.$router.push(`/exam/paper/${certExamApplyPaperId}`);
            } catch (e) {
                // ;
            }
            setTimeout(() => {
                this.submitting = false;
            }, 500);
        },
        onShowProve() {
            const {
                $refs: { proveDialog },
            } = this;
            if (!proveDialog) return;
            proveDialog.open();
        },
    },
    created() {
        this.getDetail();
    },
};
</script>

<style lang="scss" scoped>
.paper-list {
    margin-top: $margin;
    > .item {
        background-color: $color-component-bgc;
        padding: $padding;
        + .item {
            border-top: 1px solid $color-border;
        }

        > .label {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            > .count {
                font-weight: bold;
            }
        }
        > .content {
            margin-top: $margin-small;
            font-size: $little * 100%;
            color: $color-text-minor;
        }
    }
}
</style>
